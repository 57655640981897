// Summernote
import "summernote/dist/summernote-bs4.js";
import "summernote/dist/summernote-bs4.css";

import {Notification} from "./core";
import {Project} from "./project";
import {Marketplace} from "./marketplace";

export class ListingApprovalManager {
  private static timers: number[];

  /**
   * Manage the filter of records on the listing approval interface
   *
   * @param $cardContainer
   * @param badgeSelector
   * @param noListingText
   * @param mode
   */
  public static init(
    $cardContainer: JQuery<HTMLElement>,
    badgeSelector: string,
    noListingText: string,
    mode: string
  ): void {
    const $searchForm = $cardContainer.find('.search-form-container form');
    $searchForm && this.initSearchForm($cardContainer, noListingText, badgeSelector);
    this.getRowsProcessing($cardContainer, noListingText, badgeSelector, mode);
    $(document).on(
        'click',
        '.wrap-content li.page-item',
        (e) => {
          e.preventDefault();
          let $target = $(e.target);
          let $active = $('.wrap-content li.page-item.active');
          $searchForm.find('input[name="page"]').remove();
          if ($target.attr('href') != '#') {
            let page = parseInt($.trim($target.text()));
            if (isNaN(page)) {
              let stringParams = $target.attr('href').split('?')[1];
              let params = stringParams.split('&');
              page = parseInt(params.filter(s => s.match('^page='))[0].split('=')[1]);
            }
            if (!$target.parent('li').hasClass('active')) {
              if ($target.hasClass('arrow-right')) {
                $active.next().addClass('active');
              } else if ($target.hasClass('arrow-left')) {
                $active.prev().addClass('active');
              } else {
                $(".wrap-content li.page-item:contains('" + page + "')").addClass('active');
              }
              $active.removeClass('active');
              $searchForm.append('<input type="hidden" name="page" value="' + page + '">');
              $searchForm.trigger('submit', {loader: true, history: true, sorting: true});
            }
          } else {
            $("html, body").animate({scrollTop: 0}, "fast");
          }
      }).on(
          'click',
          '.table-listing-approval table thead tr th i',
          (e) => {
            const $target = $(e.target);
            const $siblings = $target.parent().siblings().find('i');
            let direction = 'asc';
            $searchForm.find('input[type="hidden"][name="order_by"]').remove();
            $siblings.removeClass('fa-sort-up fa-sort-down').addClass('fa-sort');
            if ($target.hasClass('fa-sort')) {
              // Sorting by up in this column
              $target.removeClass('fa-sort').addClass('fa-sort-up');
            } else {
              // Alternate sorting up and down in the same column
              $target.toggleClass("fa-sort-up fa-sort-down");
            }
            if ($target.hasClass('fa-sort-down')) {
              direction = 'desc';
            }
            $searchForm.append(
                '<input type="hidden" name="order_by" value="' + $target.data('sort') + ' ' + direction + '">'
            ).trigger('submit', {loader: true, history: true, sorting: true});
    });
  }

  public static initListingApproval(
    $approvalForm: any,
    $cardContainer: JQuery<HTMLElement>,
    noListingText: string,
    badgeSelector: string,
    mode: string,
    objectClass: any
  ): void {
    $approvalForm.append(`<input type="hidden" name="mode" value="${mode}">`);
    this.manageApprovalForm(
      $approvalForm,
      objectClass.listing.product_id,
      parseInt(String(objectClass.listing.marketplace_id))
    );
    $approvalForm.on('success.form.fv', (e) => {
      e.preventDefault();
      objectClass.processApprovalForm($approvalForm, badgeSelector, noListingText, $cardContainer, mode);
    })
  }

  public static manageApprovalForm($approvalForm: any, productId: string, marketplaceId: number): void {
    $approvalForm.formValidation({
      framework: 'bootstrap4',
      icon: {
        required: 'fal fa-asterisk',
        valid: 'fal fa-check',
        invalid: 'fal fa-times',
        validating: 'fal fa-refresh'
      },
      addOns: {
        mandatoryIcon: {
          icon: 'fal fa-asterisk'
        }
      },
      excluded: [':disabled'],
      fields: {
        'keyword_seo': {
          validators: {
            notEmpty: {
              message: 'The main keyword is required.'
            }
          }
        },
        'reason': {
          validators: {
            notEmpty: {
              message: 'The reason is required.'
            }
          }
        },
        custom_reason: {
          enabled: false,
          validators: {
            notEmpty: {
              message: 'The custom reason is required.'
            }
          }
        }
      }
    }).on('change', 'select[name=reason]', (e) => {
      this.manageCustomReason($approvalForm, $(e.target));
    }).on('prevalidate.form.fv', () => {
      this.preValidateForm($approvalForm);
    }).find('textarea[name="description"]')
      .summernote({
        height: '15rem',
        toolbar: [
          ['style', ['bold', 'italic', 'underline', 'clear']],
          ['font', ['strikethrough', 'superscript', 'subscript']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['height', ['height']],
          ['view', ['codeview']]
        ]
      });
    this.initializeTypeAhead($approvalForm, productId, marketplaceId);
  }

  public static getMode(): string {
    if (window.location.search.match('search-type=campaign|search-type=coupon') && window.location.search.match('search-type=campaign|search-type=coupon').length > 0) {
      return 'listing';
    } else if (window.location.search.match('search-type=code') && window.location.search.match('search-type=code').length > 0) {
      return 'code';
    }
    return 'seller';
  }

  public static afterApproval($searchForm: JQuery<HTMLElement>, lastRow: boolean): void
  {
    let page = 1;
    const $activePage = $('.page-item.active .page-link:first');
    if ($activePage.length > 0) {
      page = parseInt($activePage.text());
      if (lastRow) {
        page = page > 1 ? page - 1 : 1;
      }
    }
    if ($searchForm.find('input[type="hidden"][name="page"]').length > 0) {
      $searchForm.find('input[type="hidden"][name="page"]').val(page);
    } else {
      $searchForm.append('<input type="hidden" name="page" value="' + page + '"/>');
    }
  }

  /**
   * Populate table with data including current pagination
   *
   * @param $cardContainer
   * @param noListingText
   * @param badgeSelector
   * @param options
   */
  public static populateTable(
    $cardContainer: JQuery<HTMLElement>,
    noListingText: string,
    badgeSelector: string,
    options
  ): void {
    const $tableContainer = $cardContainer.find('.wrap-content');
    let usedDataQuery = options.data.query.split('&').map(s => s.split('=')[0]);
    let currentURLSearch = window.location.search.replace('?', '')
        .split('&').map((s) => {return {[s.split('=')[0]] : s.split('=')[1]}});
    let usefulArray = options.data.query.split('&');
    let allowedTypes = ['search', 'search-type', 'user-id', 'marketplace', 'page', 'records_per_page'];
    if (options.sorting) {
      allowedTypes.push('order_by');
    }
    $.each(currentURLSearch, (i, v) => {
      let index = Object.keys(v)[0].toString();
       if (usedDataQuery.indexOf(index) === -1 && allowedTypes.indexOf(index) !== -1) {
           usefulArray.push(index + '=' + Object.values(v)[0]);
       }
    });
    options.data.query = usefulArray.join('&');
    // if we have a slow response then we put a loader
    let tolerance = setTimeout(() => {
      if (request.state() == 'pending') {
        options.loader && Project.loader($tableContainer);
      }
    }, options.toleranceTime);
    let request = $.ajax({
      url: options.data.url,
      data: options.query ? options.query : options.data.query,
      method: options.method
    });
    request.done((response) => {
      let params = options.data.query.split('&');
      if (options.history) {
        if (!response.hasOwnProperty('page')) {
          params.splice(params.indexOf('page'), 1);
        }
        options.data.query = params.join('&');
        window.history.replaceState(
          {},
          null,
          window.location.origin + window.location.pathname + '?' + (options.query ? $.param(options.query) : options.data.query)
        );
      }
      $tableContainer.html(response.data);
      this.getRowsProcessing($cardContainer, noListingText, badgeSelector, this.getMode());
      clearTimeout(tolerance);
      tolerance = null;
    }).always(() => {
      $("#spin-loader").remove();
    });
  }

  public static refreshRow(
    $row: JQuery<HTMLElement>,
    noListingText: string,
    $cardContainer: JQuery<HTMLElement>,
    badgeSelector: string,
    mode: string
  ): void {
    const $btnProcess = $row.find('.btn-process');
    const initialText = $btnProcess.html();
    $btnProcess
      .html('<i class="fal fa-spin fa-spinner"></i> Processing...')
      .attr('disabled', 'disabled').addClass('disabled')
      .next().attr('disabled', 'disabled')
      .addClass('disabled');
    let timer: any = setInterval(() => {
      $.post(
        $cardContainer.find('.table-listing-approval table').data('checkUrl'),
        this.extractDataRow($row, mode),
        (response) => {
          this.updateBadge(badgeSelector, response.total);
          this.updateRemaining($row, response.remaining);
          if (response.remaining == 0) {
            this.removeRow($row, $cardContainer, noListingText, badgeSelector, response);
            this.stopRefresh($row, timer, initialText);
          } else {
            if (!$btnProcess.hasClass('disabled')) {
              $btnProcess
                .html('<i class="fal fa-spin fa-spinner"></i> Processing...')
                .attr('disabled', 'disabled')
                .addClass('disabled')
                .next().attr('disabled', 'disabled')
                .addClass('disabled');
            }
          }
        }).fail(() => {
        this.stopRefresh($row, timer, initialText);
      });
    }, 1000);
    this.timers.push(timer);
  }

  /**
   * Update remaining of the row
   * @param $row
   * @param remaining
   */
  public static updateRemaining($row: JQuery<HTMLElement>, remaining: string): void {
    $row.find('.remaining').html(remaining);
  }

  public static getRowsProcessing(
    $cardContainer: JQuery<HTMLElement>,
    noListingText: string,
    badgeSelector: string,
    mode: string
  ) {
    for (let timer in this.timers) {
      clearInterval(this.timers[timer]);
      this.timers[timer] = null;
    }
    this.timers = [];
    // Detect current elements being processed
    const $disabledRows = $('.btn-process.disabled');
    if ($disabledRows.length > 0) {
      // Launch check approval progress
      $disabledRows.closest('tr').each((index, row) => {
        this.refreshRow($(row), noListingText, $cardContainer, badgeSelector, mode);
      });
    }
  }

  /**
   * extract data from row to use in a request
   * @param $row
   * @param mode
   */
  public static extractDataRow($row, mode) {
    let data = {mode: mode};
    $.each($row.data(), (i, v) => {
      let index = i.toString().split(/(?=[A-Z])/).join('_').toLowerCase();
      data[index] = v;
    });
    return data;
  }

  public static changeSearchType($searchForm, $target)
  {
    const searchType = $searchForm.find('input[name="search-type"]:checked');
    const $inputSearch = $searchForm.find('input[name="search"]');
    const $marketplace = $searchForm.find('select[name="marketplace"]');
    let type = searchType.val();
    switch (searchType.val()) {
      case 'id':
        type = 'seller';
        break;
      case 'code':
        type = 'seller';
        break;
    }
    if (searchType.val() == 'code' || searchType.val() == 'coupon') {
      $inputSearch.attr('placeholder', 'Search ' + type + ' per ID / Name / Code');
    } else {
      $inputSearch.attr('placeholder', 'Search ' + type + ' per ID / Name');
    }
    $("[name='search-type']").removeAttr('checked');
    // Clean search type hidden data
    $('input[type="hidden"][name="search-type"]').remove();
    $('#filter-by').html('By ' + searchType.siblings().html());
    $inputSearch.val('');
    $marketplace.val('');
    $inputSearch.trigger('focus');
    $target.prop('checked', true).attr('checked', 'checked');
    $searchForm.trigger(
        'submit',
        {
          loader: true,
          history: true,
          query: {'search-type': searchType.val()}
        }
    );
  }

  /**
   *
   * @private
   * @param $cardContainer
   * @param noListingText
   * @param badgeSelector
   */
  private static initSearchForm(
    $cardContainer: JQuery<HTMLElement>,
    noListingText: string,
    badgeSelector: string
  ) {
    const $searchForm = $cardContainer.find('.search-form-container form');
    $searchForm.on('submit', (e, settings) => {
      e.preventDefault();
      (!settings.sorting && $searchForm.find('input[type="hidden"][name="order_by"]').remove());
      this.populateTable($cardContainer, noListingText, badgeSelector, {
        loader: settings && settings.loader ? settings.loader : false,
        history: settings && settings.history ? settings.history : false,
        toleranceTime: settings && settings.toleranceTime ? settings.toleranceTime : 500,
        method: $(e.target).attr('method'),
        sorting: settings && settings.sorting ? settings.sorting : false,
        query: settings && settings.query ? settings.query : false,
        data: {
          'url': $cardContainer.find('.table-listing-approval').data('url'),
          'query': $(e.target).serialize()
        }
      });
    }).on('click', '.dropdown-item', (e) => {
      if ($(e.target).prop("tagName") === 'DIV') {
        e.preventDefault();
        $("[name='search-type']").removeAttr('checked');
        $(e.target).children('input').prop('checked', true)
            .attr('checked', 'checked')
            .trigger('change');
      }
    }).on('change', 'input[name="search-type"], #marketplace, #r-page', (e) => {
      e.preventDefault();
      if ($(e.target).attr('name') !== 'search-type') {
        $searchForm.trigger('submit', {loader: true, history: true, sorting: true});
      } else {
        this.changeSearchType($searchForm, $(e.target));
      }
    }).on('input', 'input[type="search"][name="search"]', (e) => {
      e.preventDefault();
      $searchForm.trigger('submit', {loader: false, history: true, sorting: true});
    });
  }

  /**
   * Run typeahead component
   *
   * @param $approvalForm
   * @param keyword
   * @param marketplaceId
   * @private
   */
  public static initializeTypeAhead($approvalForm: any, keyword: string, marketplaceId: number): void {
    // Now It works just for amazon
    if (marketplaceId === Marketplace.AMAZON) {
      $approvalForm.find('.form-control-autocomplete-keyword').each((index, element) => {
        const $search = $(element);
        const options = {
          url: '/ajax/keyword',
          prepare: (query, settings) => {
            settings.url += '?asin=' + keyword.toString() + '&search=' + $search.val().toString();

            return settings;
          }
        };

        const keywords = new Bloodhound({
          datumTokenizer: Bloodhound.tokenizers.obj.whitespace('keyword'),
          queryTokenizer: Bloodhound.tokenizers.whitespace,
          remote: options
        });

        const inputOptions = {
          hint: true,
          highlight: true,
          minLength: 0
        };

        const datasets = {
          name: 'keywords',
          display: 'keyword',
          limit: 1000,
          source: keywords,
          templates: {
            suggestion(data) {
              return `<div class="d-flex justify-content-between">
                                    <span>${data.keyword}</span>
                                  </div>`;
            }
          }
        };
        $search.typeahead(inputOptions, datasets)
          .on('typeahead:asyncrequest', () => {
            $search.parents('.twitter-typeahead')
              .append('<div class="tt-loader"><i class="fal fa-spin fa-spinner"></i></div>');
          })
          .on('typeahead:asynccancel typeahead:asyncreceive', () => {
            $search.parents('.twitter-typeahead').find('.fa-spinner').remove();
          })
          .on('typeahead:selected', () => {
            $approvalForm.formValidation('revalidateField', 'keyword_seo');
          })
          .on('typeahead:closed', () => {
            $approvalForm.formValidation('revalidateField', 'keyword_seo');
          })
          .siblings('input').data('fv.messages', $search.data('fv.messages'));
      });
    }
  }

  public static preValidateForm($approvalForm: any) {
    const fv = $approvalForm.data('formValidation');
    const action = String(fv.getSubmitButton().attr('value'));
    if (action === 'approve' || action === 'approve_all') {
      $approvalForm.formValidation('enableFieldValidators', 'reason', false);
    } else {
      $approvalForm.formValidation('enableFieldValidators', 'reason', true);
      $approvalForm.formValidation('enableFieldValidators', 'keyword_seo', false);
    }
    fv.revalidateField('reason');
    fv.revalidateField('keyword_seo');
  }

  public static manageCustomReason($approvalForm: any, $reason: JQuery<HTMLElement>) {
    const $custom = $approvalForm.find('input[name=custom_reason]').parents('.form-group');
    const fv = $approvalForm.data('formValidation');
    if (parseInt(<string>$reason.val()) === 3) {
      $custom.removeClass('none');
      $approvalForm.formValidation('enableFieldValidators', 'custom_reason', true);
      fv.revalidateField('reason');
    } else {
      $custom.addClass('none');
      $approvalForm.formValidation('enableFieldValidators', 'custom_reason', false);
      fv.revalidateField('reason');
    }
  }

  public static stopRefresh($row: JQuery<HTMLElement>, timer: NodeJS.Timeout, buttonText: string): void {
    $row.find('.btn-process')
      .html(buttonText)
      .removeAttr('disabled').removeClass('disabled');
    clearInterval(timer);
    timer = null;
  }

  /**
   * Update remaining values after some transaction with the server.
   *
   * @param badgeSelector
   * @param total
   * @private
   */
  public static updateBadge(badgeSelector: string, total: number): void {
    let $badge = $(badgeSelector).children('span.badge');
    // Update badge from menu
    if ($badge.length > 0) {
      $badge.html(String(total));
      if (parseInt($badge.html()) == 0) {
        $badge.remove();
      }
    }
  }

  public static removeRow(
    $row: JQuery<HTMLElement>,
    $cardContainer: JQuery<HTMLElement>,
    noListingText: string,
    badgeSelector: string,
    response
  ): void {
    const $tableContainer = $cardContainer.find('.table-listing-approval');
    const $searchForm = $cardContainer.find('.search-form-container form');
    let lastRow = $tableContainer.find('table tbody tr').length == 1;
    ListingApprovalManager.afterApproval($searchForm, lastRow);
    $row.fadeOut('slow', () => {
      if (lastRow && !$tableContainer.data('url')) {
        $row.parent('tbody').html(noListingText);
      } else {
        $row.remove();
      }
      this.updateBadge(badgeSelector, response.total);
      this.updateRemaining($row, response.remaining);
      $searchForm.trigger('submit', {loader: false, history: true, sorting: true});
    });
  }
}
