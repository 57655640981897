import {Notification, Modal} from "./core";
import {ListingApprovalManager} from "./listing-approval-manager";

interface RebateListing {
  id: bigint,
  marketplace_id: number,
  product_id: string,
  user_id: bigint
}

export class RebateListingApproval {
  /**
   * Declare settings to use within rebate approval process
   * @private
   */
  private readonly listing: RebateListing;

  /**
   * Receive necessary data in constructor
   * @param listing
   */
  constructor(listing: RebateListing) {
    this.listing = listing;
  }

  /**
   * Initialize rebate listing approval system
   * @param $approvalForm
   * @param $cardContainer
   * @param noListingText
   * @param badgeSelector
   * @param mode
   */
  public init($approvalForm: any, $cardContainer: JQuery<HTMLElement>, noListingText: string, badgeSelector: string, mode: string): void {
    ListingApprovalManager.initListingApproval($approvalForm, $cardContainer, noListingText, badgeSelector, mode, this);
  }

  private processApprovalForm(
    $form: JQuery<HTMLElement>,
    badgeSelector: string,
    noListingText: string,
    $cardContainer: JQuery<HTMLElement>,
    mode: string
  ): void {
    const $row = this.getRow(mode);
    const $submitButton = $form.data('formValidation').getSubmitButton();
    const action = String($submitButton.attr('value'));
    const $btnProcess = $row.find('.btn-process');
    const label = $submitButton.html();
    $form.append('<input type="hidden" name="action" value="' + action + '">');
    $submitButton.html('<i class="fal fa-spin fa-spinner"></i> Processing...');

    $.post('/ajax/campaigns/campaigns-approval', $form.serializeArray()).done((response) => {
      response.message && Notification.success(response.message);
      if (response.total) {
        ListingApprovalManager.updateBadge(badgeSelector, response.total);
      }
      if (response.next_rebate > 0) {
        ListingApprovalManager.updateRemaining($row, response.remaining);
        $btnProcess
          .attr('href', `html/campaign/details?user_id=${this.listing.user_id}`)
          .html('Process')
          .next().removeAttr('disabled').removeClass('disabled');
        Modal.reload({
          href: `html/campaign/details?user_id=${this.listing.user_id}`
        });
      } else if (response.remaining == 0) {
          ListingApprovalManager.removeRow($row, $cardContainer, noListingText, badgeSelector, response);
          if (action == 'disapprove' || action == 'approve') {
            Modal.hide();
          }
      }
    }).fail(() => {
      $form.data('formValidation') !== undefined && $form.data('formValidation').resetForm();
      $submitButton.html(label);
    });
    if (action == 'disapprove_all' || action == 'approve_all') {
      ListingApprovalManager.refreshRow($row, noListingText, $cardContainer, badgeSelector, mode);
      Modal.hide();
      Notification.info("Working, please be patient...");
    }
  }

  /**
   * Keep reference of the row to be processed
   * @private
   */
  private getRow($mode: string): JQuery<HTMLElement> {
    if ($mode == 'listing') {
      return $(document).find(`tr[data-id=${this.listing.id}]`);
    }
    return $(document).find(`tr[data-user-id=${this.listing.user_id}]`);
  }
}
